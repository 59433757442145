import React, { useState } from 'react'
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import hdfc from '../../assets/icons/hdfc-logo.jpg'
import easebuzz from '../../assets/icons/easybuzz-logo.png'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import './paymentPage.css'
export default function PaymentPage() {

  const[studentName,setStudentName]= useState('Raju')
  const[instituteName,setInstituteName]= useState("Feemonk Edge ")
  const[courseFees,setCourseFees]= useState(30000)
  const[studentCourse,setStudentCourse]= useState('DSA')
  const[studentPhone,setStudentMobile]= useState('9493613197')
  const[studentEmail,setStudentEmail]= useState('rajup@gmail.com')
  const [errors, setErrors] = useState({})
  const body={
    mobile: studentPhone,
    course: studentCourse,
    courseFees: courseFees,
    studentName: studentName,
    instituteName: instituteName,
    studentEmail:studentEmail,
    channelId:2,
    flow:'pay-later'
  }
  const sendBody = btoa(JSON.stringify(body))
  
  const [paymentMode,setPaymentMode]=React.useState(1)
  const [loadingPage,setLoading]=React.useState(false)
  const [pageBlur,setPageBlur]=React.useState(false)

  React.useEffect(() => {
    setPageBlur(loadingPage)
  
    
  }, [loadingPage])
  

  const testEaseBuzz=()=>{
    setLoading(true)
     const urlEaseBuzz=`/easebuzz/payment_link/create`
    const payload= {  amount:   parseFloat(courseFees),
        productinfo : "Fee Collection",
        phone : studentPhone,
        firstname : studentName,
        email : studentEmail
    }
    axios.post(`https://institute-apis.feemonk.com`+urlEaseBuzz,payload)
    .then((res)=>{
      console.log(res)
      setLoading(false)
      window.open(res?.data?.res)

    })
    .catch((err)=>{
      console.log(err)
      window.open('popup')
    })

  }

  const validateEmail = email => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const validateFields = () => {
    let formErrors = {}
    if(!studentName){
      formErrors.studentName  = 'Enter student name'
    }
    if(!validateEmail(studentEmail)){
      formErrors.studentEmail  = 'Enter a valid email'
    }
    if(!studentCourse){
      formErrors.studentCourse  = 'Enter course name'
    }
    if((courseFees <= 0 || !courseFees) ){
      formErrors.courseFees  = 'Course fee must be more than 0'
    }
    if(studentPhone?.length < 10){
      formErrors.studentPhone  = 'Mobile number must be 10 digits'
    }
    if(!instituteName){
      formErrors.instituteName  = 'Enter institute name'
    }

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
      
  }

  const handlePaylater = () => {
      if(validateFields()){
        window.open(`https://staging-paylater.feemonk.com/pan-details/${sendBody}`,"_blank")
      }
  }

  return (
    <div className="payment-details">
      <Box sx={{ flexGrow: 1, padding:5 }}>
        <p className="title-head">Student Details</p>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <p className="title-para">Name</p>
        </Grid>
        <Grid item xs={8}>
          <TextField id="outlined-basic"  value={studentName} onChange={(e)=>setStudentName(e.target.value)} label="Name" variant="outlined" error={!!errors.studentName} helperText={errors.studentName}/>
        </Grid>
        <Grid item xs={4}>
          <p className="title-para">Email</p>
        </Grid>
        <Grid item xs={8}>
          <TextField id="outlined-basic"  value={studentEmail} type='email' onChange={(e)=>setStudentEmail(e.target.value)} label="Email" variant="outlined"  error={!!errors.studentEmail} helperText={errors.studentEmail} />
        </Grid>
        <Grid item xs={4}>
          <p className="title-para">Phone</p>
        </Grid>
        <Grid item xs={8}>
          <TextField id="outlined-basic"  value={studentPhone} type='number' onChange={(e)=>setStudentMobile(e.target.value)} label="Phone" variant="outlined"  error={!!errors.studentPhone} helperText={errors.studentPhone}/>
        </Grid>

        <Grid item xs={4}>
          <p className="title-para">Course</p>
        </Grid>
        <Grid item xs={8}>
          <TextField id="outlined-basic"  value={studentCourse} onChange={(e)=>setStudentCourse(e.target.value)} label="Course" variant="outlined"  error={!!errors.studentCourse} helperText={errors.studentCourse}/>
        </Grid>
        <Grid item xs={4}>
          <p className="title-para">Course Fees</p>
        </Grid>
        <Grid item xs={8}>
          <TextField id="outlined-basic"  type='number' value={courseFees} onChange={(e)=>setCourseFees(e.target.value)} label="Course Fees" variant="outlined"  error={!!errors.courseFees} helperText={errors.courseFees}/>
        </Grid>
      
        
      </Grid>

<Grid container justifyContent="space-around" alignContents="center" style={{borderRadius:'5px',boxShadow:'0px 0px 4px 0px rgb(0 , 0, 0.2,0.6)'}} mt={5} pt={2} spacing={1} >
      
    <Grid xs={5} mt={1.8}>
        <Grid xs={3}>
         <Button  onClick={handlePaylater} style={{color:"black" ,width:'15em', backgroundColor:'	rgb(255,165,0,0.5)',}}>Pay later with <span style={{color:'red',fontWeight:'bold', marginLeft:'5px'}}> Fee</span><span style={{color:'black',fontWeight:'bold'}}>monk</span>
         </Button>
        </Grid>
      </Grid>
      <Grid  xs={4} mt={2}>
       { !pageBlur?<Button onClick={()=>testEaseBuzz()} style={{backgroundColor:'#d32028',width:'8rem', color: 'white',fontWeight:'bold'
}}>Pay Now</Button>:<Button loading  style={{backgroundColor:'#d32028',width:'8rem',  color: 'white',fontWeight:'bold'
}}>Loading</Button>}
<Typography  color="black">powered by {paymentMode!==1?<img style={{ width:'6rem',height:'4rem'}} src={hdfc}/>:<img style={{ marginTop:'-1rem',width:'5rem',height:'5rem'}} src={easebuzz}/>}</Typography>
      </Grid>
  </Grid>
    
    </Box>
      </div>
  )
}
